import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    display: 'flex',
    flexDirection:'column',
    position: "relative",
    width: "100%",
    overflow: "hidden",
    height: '100%',
    paddingTop: theme.spacing(2),
  },
  listContainer: {
    flexGrow: 1,
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    "& .card": {
      margin: theme.spacing(1),
    }
  },

  info: {
    marginLeft: theme.spacing(1)
  },
  paperInfo:{
    padding: theme.spacing(2, 3),
    width: '100%'
  },
  paginacao:{
    padding: theme.spacing(0, 1)
  },
  saldoContainer: {
    display: 'flex', 
    width: '100%', 
    justifyContent: 'flex-end'
  },

  //styles IOS
  defaultContainerIOS: {
    display: 'flex',
    flexDirection: 'column',
    position: "relative",
    width: "100%",
    overflow: "hidden",
    height: '100%',
  },
  paginacaoIOS: {
    height: '10%',
    padding: theme.spacing(0, 1),
    '& div': {
      marginTop: 0
    }
  },
  listContainerIOS: {
    height: '36%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    "& .card": {
      margin: theme.spacing(1),
    }
  },
  saldoContainerIOS: {
    display: 'flex',
    width: '100%',
    height: '29%',
    justifyContent: 'flex-end'
  },
  filtroContainerIOS: {
    height:'25%'
  }
}));
