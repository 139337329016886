import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  label: {
    fontSize: '0.9rem',
    color: '#BCBCBC',
    lineHeight: '14,1px'
  },
  subtitle: {
    fontWeight: 600,
    color: '#8b8b8b'
  },
  cancelar: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    '&:disabled': {
      backgroundColor: theme.palette.error.light
    }
  },
  container: {
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: '100%',
    background: '#fafafa',
    zIndex: 99999
  },
  containerIOS: {
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: 'calc(100vh - 70px)',
    background: '#fafafa',
    zIndex: 99999
  },
  
  depositoEstoque: {
    padding: '8px',
    overflowY: 'auto',
    maxHeight: () => window.innerHeight - 100
  },
  cardInfo: {
    fontWeight: 500,
  },
  cardMovimentacoesContent: {
    boxShadow: '0px 4px 4px 0px #00000014',
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  tabsContent: {
    height: '100%',
    boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset',
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 0
    }
  },
  cardGrid:{
    padding: theme.spacing(1, 2) 
  },
  content:{
    display: 'flex', 
    flexDirection: 'column', 
    flex: 1, 
    height: '100%' 
  }
}));
